.card--test__container {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: flex-end;
    margin: 50px 0;
}

.card__img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    display: block;
}

.card__img-wrapper {
    overflow: hidden;
    height: 100%;
}


.card--test {
    position: relative;
    /* height: 600px; */
    overflow: hidden;
    flex-basis: 25%;
    transition-duration: 0.4s;
    margin: 0 20px 30px 20px;
    cursor: pointer;
}

.card--test__body {
    position: absolute;
    transition-duration: 0.4s;
    bottom: 0;
    z-index: 1;
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    background-color: rgba(255, 255, 255, 0.7);
}

/* .card--test:hover .card--test__body {
    top: 0;
    background-color: white;
    
    
    
}
.card--test:hover::after {
    opacity: 0;
} */

.card--test__header {
    /* height: 75%; */
    width: 100%;
}

.card__title {
    margin: 0;
    font-size: 40px;
    font-weight: 400;
    color: #8A8EA0;
    line-height: 3vw;
}

.card__subtitle {
    margin: 0;
    font-size: 16px;
    font-weight: 300;
    color: #807460;
}

.card__info {
    line-height: 3vw;
    /* max-height: 80%; */
    /* overflow-y: scroll; */
    font-size: 14px;
    color: #807460;
    font-style: italic;
}

.card__btn-wrapper {
    text-align: right;
    margin-bottom: 15px;
}

.card__btn {
    text-decoration: none;
    background: #CB997E;
    color: white;
    font-size: 20px;
    padding: 15px;
}


.specialists_swiper {
    display: none;
}

@media screen and (max-width: 1024px) {
    .card--test {
        flex-basis: 30%;
        margin: 0;
        margin-bottom: 30px;
    }

    .card__title {
        font-size: 20px;
    }

    /* .card__info {
        font-size: 13px;
    } */
    .card__btn {
        font-size: 14px;
        padding: 10px;
    }
}

@media screen and (max-width: 800px) {
    .card--test__container {
        display: none;
    }

    .specialists_swiper {
        display: block;
    }
}

@media screen and (max-width: 550px) {
    .card--test {
        margin: 0 10%;
    }

    .card--test__title-wrapper {
        text-align: center;
    }

    .card__title {
        font-size: 35px;
        line-height: 8vw;
    }

    .card__subtitle {
        font-size: 16px;
    }
}

@media screen and (max-width: 350px) {


    .card__title {
        font-size: 20px;
        line-height: 20px;
    }

    .card__subtitle {
        font-size: 14px;
    }

    .card__info {
        line-height: 30px;
        font-size: 16px;
    }
}