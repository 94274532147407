.kindsOfService-promo__content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100vh;
}
.kindsOfService-promo__img {
    overflow: hidden;
    /* width: 30vw; */
    /* height: 100vh; */
    flex-basis: 40%;
    height: 100%;
}
.kindsOfService-promo__img img {
    width: 100%;
    height: 100%;
    display: block;
    object-fit: cover;
}
.kindsOfService-promo__descr {
    max-width: 50vw;
    background: transparent;
}
h1.kindsOfService-promo__title {
    font-size: 4.77778vw;
    color: #50403F;
    font-weight: 200;
    font-style: italic;
}
p.kindsOfService-promo__text {
    color: #8D7262;
    font-weight: 500;
    font-size: 16px;
    line-height: 1.7;
    letter-spacing: .02em;
}
.kindsOfService__cards-container {
    display: flex;
    flex-wrap: wrap;
    position: relative;
    justify-content: space-between;
    margin-top: 50px;
    padding-bottom: 70px;
}

.swiper-pagination-custom--subservice {
    display: block;
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
}
.swiper-pagination-custom--subservice .swiper-pagination-bullet, .swiper-pagination-custom--subservice .swiper-pagination-bullet-active {
    background-color: #000!important;
    cursor: pointer;
}

@media screen and (max-width: 800px) {
    .kindsOfService-promo__img {
        height: 70vh;
    }
}
@media screen and (max-width: 700px) {
    .kindsOfService-promo__img {
        height: 50vh;
        position: sticky;
        top: 100px;
    }
}
@media screen and (max-width: 600px) {
    .kindsOfService-promo__content {
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: auto;
    }
    .kindsOfService-promo {
        margin-top: 50px;
    }
    .kindsOfService-promo__descr {
        max-width: 90%;
        text-align: center;
        background: white;
        padding: 2rem;
        position: relative;
        margin-top: 20px;
    }
}