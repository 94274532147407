.swiper-container {
    width: 100%;
    padding-top: 50px;
    padding-bottom: 50px;
    
}



.gallery__wrapper .swiper-slide {
    background-position: center;
    background-size: cover;
    width: auto;
    height: 300px;
    margin: 0 auto;
}



.gallery__img-wrapper {
    overflow: hidden;
    height: 100%;
}
.gallery__img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.swiper-pagination-custom {
    display: block;
    margin-top: 25px;
    /*height: 10px;*/
    text-align: center;
}
.swiper-pagination-bullet {
    margin-right: 10px;
    display: inline-block;
    border-radius: 0!important;
    width: 10px;
    height: 10px;
    background:#918679!important;
}
.swiper-pagination-bullet-active {
    background: #8D7262!important;
}




