.promo {
    display: block;
}

.yButton {
    display: none;
}

@media screen and (max-width: 1000px) {
    .promo-wrapper {
        display: none;
    }
}