.parallax-about-min {
    display: none;
}
.about__img {
    /* background: url(../../../Assets/content/sign.jpg) center no-repeat; */
    background: url(../../../Assets/content/sig.JPG) center no-repeat;
    background-size: cover;
    height: 70vh;
    position: relative;
}
.about__img::after {
    content: '';
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0, 0.3);
}
.about__title {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 10;
    color: #EDDCD2;
    font-size: 40px;
    font-weight: 400;
    text-align: center;

}
.about__subtitle {
    font-size: 6.77778vw;
    color: #8A8EA0;
    font-weight: 500;
    font-style: italic;
    line-height: 7vw;

}

.about__subtitle--no-margin {
    margin-top: 0;
}

.about__info {
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 70vh;
    /* height: 40vh; */
}
.about__info--reverse {
    flex-direction: row-reverse;
}
.about__info--center {
    flex-direction: column;
    justify-content: center;
    margin-top: 5vw;
}
.about__info--center .about__subtitle {
    flex-grow: 0;
}
.about__info--center .about__text {
    text-align: center;
    width: 70vw;
}

.about__info--reverse .about__subtitle {
    text-align: right;
}
.about__text-wrapper {
    flex-basis: 50%;
}
.about__text-wrapper--mobile {
    display: none;

}
.about__text-wrapper .react-parallax {
    overflow: unset!important;
}
.about__text {
    padding: 2vw;
    color: #514240;
    font-weight: 500;
    font-size: 22px;
    line-height: 1.7;
    letter-spacing: .02em;
}
.about__subtitle {
    flex-grow: 1;
}


@media screen and (max-width: 1400px) {
    .about__text {
        font-size: 20px;
    }
}
@media screen and (max-width: 1200px) {
    .about__text {
        font-size: 16px;
    }
}
@media screen and (max-width: 750px) {
    .about__text {
        text-align: center;
    }
    .about__info {
        flex-direction: column;
    }
    .parallax-about-min {
        display: block;
    }
    .parallax-about {
        display: none;
    }
}

@media screen and (max-width:600px) {
    
    .about__info {
       height: 50vh;
       margin-top: 10vh;
    }
    .about__text-wrapper {
        display: none;
    }
    .about__text-wrapper--mobile {
        display: block;
        
    }
    .about__text {
        margin: 0;
    }
    .about__info--center {
        height: 70vh;
    }
    .about__img {
        background: url(../../../Assets/content/about-parallax.JPG) center no-repeat;
    }
}