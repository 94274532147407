.aboutPage-promo {
    background-image: url(../../Assets/content/about-parallax-big.JPG);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top;
    height: 100vh;
    position: relative;
}
.aboutPage-promo::after {
    content: '';
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;
    top: 0;
    /* background: rgba(0, 0, 0, 0.3); */
    background: rgba(247, 239, 227, 0.3);
}

h1.aboutPage-promo__title {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 10;
    /* color: #EDDCD2; */
    color: black;
    font-size: 70px;
    font-weight: 400;
}
.main__title--aboutPage {
    font-size: 50px;
    color: #8A8EA0;
    font-weight: 500;
    font-style: italic;
    width: 60.97222vw;
}
.main__title--aboutPage span {
    font-size: 30px;
    color: black;
}
.aboutPage-descr {
    margin-left: auto;
    width: 40.06944vw;
    max-width: 100%;
}
.aboutPage-descr p {
    color: #8D7262;
    font-weight: 500;
    font-size: 20px;
    line-height: 1.7;
    letter-spacing: .02em;
}
.aboutPage-descr h2 {
    color: #50403F;
    font-size: 24px;
    font-weight: 600;
}
.mobile-descr {
    display: none;
    color: #8D7262;
    font-weight: 500;
    font-size: 20px;
    line-height: 1.7;
    letter-spacing: .02em;
}


.concept {
    display: flex;
    margin: 8vw 0;
    position: relative;
}
/* .concept::after {
    content: "";
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
} */

.concept__img {
    overflow: hidden;
    
}
.concept__img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: block;
}
.concept__text {
    /* width: 55.41667vw; */
    padding: 3.75vw 0;
    /* margin-top: auto; */
    margin-left: 15vh;
    width: 70%;
    /* margin-bottom: auto; */
    /* margin-left: 11.25vw; */
    margin-right: 0;
    /* width: 70%; */
    
}
.concept__text p {
    color: #8D7262;
    font-weight: 500;
    font-size: 20px;
    line-height: 1.7;
    letter-spacing: .02em;
}

.specialists {
    margin: 15vw 0;
}

.overflow-unset {
    overflow: unset!important;
}
.parallax-unset {
    position: unset!important;
}
.xy-full {
    width: 100%;
    height: 100%;
}

@media screen and (max-width: 1024px) {
    /*.concept {*/
    /*    position: relative;*/
    /*}*/
    /*.concept__img {*/
    /*    width: 80%;*/
    /*}*/
    /*.concept__text {*/
    /*    position: absolute;*/
    /*    width: 40%;*/
    /*    padding: 40px;*/
    /*    top: 50%;*/
    /*    right: 0;*/
    /*    transform: translate(-30%, -50%);*/
    /*    margin: 0;*/
    /*    background: #fff;*/
    /*}*/
    h1.aboutPage-promo__title {
        font-size: 45px;
    }
    .main__title--aboutPage {
        font-size: 40px;
        margin-top: 10vh;
    }
    .concept__title {
        margin-top: 0;
    }
    .aboutPage-descr p {
        font-size: 16px;
    }
    .concept__text p {
        font-size: 16px;
    }
}
@media screen and (max-width: 800px) {
    .concept {
        margin: 100px 0;
    }
    .aboutPage-descr {
        display: none;
    }
    .mobile-descr {
        display: block;
    }
    .concept__img {
        width: 50%;
    }
}
@media screen and (max-width: 700px) {
    .concept__img {
        display: none;
    }
}
@media screen and (max-width: 500px) {
    h1.aboutPage-promo__title {
        font-size: 35px;
    }
    .aboutPage-promo {
        background: url(../../Assets/content/about-parallax.JPG) center no-repeat;
        height: 70vh;
    }
    /* .concept {
        background: url(../../Assets/content/concept.jpg) center no-repeat;
        background-size: contain;
    } */

    .concept__text {
        /* width: 100%;
        text-align: center; */
        margin: 0 auto;
    }
    .concept__title {
        font-size: 40px;
    }
}
