@media screen and (max-width: 1000px) {
    .page_wrapper {
        margin-top: 83px;
    }
}
.booking__wrapper {
    position: fixed;
    height: 100px;
    width: 100px;
    transition: transform 0.3s, color 0.3s;
    background: #d2a84a;
    z-index: 2;
    color: white;
    border-radius: 50%;
    left: 10px;
    bottom: 10px;
    cursor: pointer;
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;

}

.booking__wrapper:hover {
    transform: scale(1.13);
    color: #4a310c;
}

@media screen and (max-width: 1000px) {
    .booking__wrapper {
        display: flex;
    }
}