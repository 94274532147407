.promo-image {
    background-attachment: fixed;
}
@media screen and (max-width: 1024px) {
    .promo-image {
        background-attachment: unset;
    }
}


.container {
    width: 90%;
    display: block;
    margin: 0 auto;
}
.promo {
    height: 100vh;
    background-image: url(../../Assets/design/promo-bg.jpg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    position: relative;
    z-index: 3;
}



/* MOBILE BOOKING*/

.feedback-wrapper {
    position: absolute;
    /* width: 90vw; */
    width: 60vw;
    height: 60vw;
    border-radius: 50%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 50px;
    /* background: rgba(141,114,98, 0.8); */
    background: rgb(230 222 216 / 80%);
    display: none;
    flex-direction: column;
    justify-content: center;
}
.booking-wrapper {
    overflow: hidden;
    position: relative;
    margin: 0 0 10px;
    display: none;
    
}
.booking-wrapper:before {
    background: #ceb286;
    content: "";
    height: 155px;
    left: -75px;
    opacity: 0.2;
    position: absolute;
    top: -50px;
    transform: rotate(
35deg
);
    transition: all 2s cubic-bezier(0.19, 1, 0.22, 1);
    width: 50px;
    /* z-index: -10; */
}
.toBook-wrapper:hover {
    text-decoration: none;
}
.toBook-wrapper:hover::before {
    left: 120%;
    transition: all 2s cubic-bezier(0.19, 1, 0.22, 1);
}
.booking {
    z-index: 20;
    background: transparent;
    outline: none;
    border: none;
    font-size: 40px;
    display: block;
    text-align: center;
    /* color: #EFEAE5; */
    color: #3e2619;
    font-weight: 400;
    border-bottom: 2px solid #4a310c;
    border-top: 2px solid #4a310c;
    background: rgb(230 222 216 / 50%);
    width: 80%;
}
.booking-wrapper__tel {
    display: block;
    cursor: pointer;
    text-align: center;
    font-size: 25px;
    color: #3e2619;
    font-weight: 600;
    margin-top: 20px;
    position: relative;
}
.booking-wrapper__tel::before {
    content: "";
    position: absolute;
    display: block;
    width: 20px;
    height: 20px;
    background-image: url(../../Assets/design/phone-call.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    left: -15%;
    transform: translateY(30%);
}


@-webkit-keyframes sonarEffect {
	0% {
		opacity: 0.3;
	}
	40% {
		opacity: 0.5;
		box-shadow: 0 0 0 2px rgba(255,255,255,0.1), 0 0 10px 10px #8D7262, 0 0 0 10px rgba(255,255,255,0.5);
	}
	100% {
		box-shadow: 0 0 0 2px rgba(255,255,255,0.1), 0 0 10px 10px #8D7262, 0 0 0 10px rgba(255,255,255,0.5);
		-webkit-transform: scale(1.5);
		opacity: 0;
	}
}
@-moz-keyframes sonarEffect {
	0% {
		opacity: 0.3;
	}
	40% {
		opacity: 0.5;
		box-shadow: 0 0 0 2px rgba(255,255,255,0.1), 0 0 10px 10px #8D7262, 0 0 0 10px rgba(255,255,255,0.5);
	}
	100% {
		box-shadow: 0 0 0 2px rgba(255,255,255,0.1), 0 0 10px 10px #8D7262, 0 0 0 10px rgba(255,255,255,0.5);
		-moz-transform: scale(1.5);
		opacity: 0;
	}
}
@keyframes sonarEffect {
	0% {
		opacity: 0.3;
	}
	40% {
		opacity: 0.5;
		box-shadow: 0 0 0 2px rgba(255,255,255,0.1), 0 0 10px 10px #8D7262, 0 0 0 10px rgba(255,255,255,0.5);
	}
	100% {
		box-shadow: 0 0 0 2px rgba(255,255,255,0.1), 0 0 10px 10px #8D7262, 0 0 0 10px rgba(255,255,255,0.5);
		transform: scale(1.5);
		opacity: 0;
	}
}
.whatsapp-link__wrapper {
    padding: 10px;
    background-color: #EFEAE5;
    width: 70px;
    height: 70px;
    position: fixed;
    z-index: 90;
    border-radius: 10px;
    bottom: 10px;
    right: 10px;
}
.whatsapp-link {
    width: 100%;
    height: 100%;
    background-image: url(../../Assets/design/whatsapp-link.png);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    /* position: absolute; */
    /* bottom: 10%;
    right: 10%; */

    transition: transform ease-out 0.4s, background 0.2s;
    display: inline-block;
    cursor: pointer;

    /* z-index: 1; */
}
/* .whatsapp-link::before {
    speak: none;
    line-height: 90px;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    display: block;
    -webkit-font-smoothing: antialiased;

} */
.whatsapp-link::after {
    -webkit-animation: sonarEffect infinite .8s ease-out 75ms;
    -moz-animation: sonarEffect infinite .8s ease-out 75ms;
    animation: sonarEffect infinite .8s ease-out 75ms;
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    content: '';
    -webkit-box-sizing: content-box;
    -moz-box-sizing: content-box;
    box-sizing: content-box;
    top: 0;
    left: 0;
    padding: 0;
    z-index: -1;
    box-shadow: 0 0 0 2px rgb(255 255 255 / 10%);
    opacity: 0;
    -webkit-transform: scale(0.9);
    -moz-transform: scale(0.9);
    -ms-transform: scale(0.9);
    transform: scale(0.9);
}
.whatsapp-link:hover {
    /* background: rgba(255,255,255,0.05); */
    -webkit-transform: scale(1.15);
    -moz-transform: scale(1.15);
    -ms-transform: scale(1.15);
    transform: scale(1.15);
}
.whatsapp-link:hover:after {
    animation: unset;
}
.promo__text {
    width: 50%;
    position: absolute;
    bottom: 10%;
}
.promo__text--mobile {
    display: none;
    background-image: url(../../Assets/design/full-logo.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    position: absolute;
    height: 70vh;
    width: 80vw;
    bottom: 10%;
    left: 50%;
    transform: translateX(-50%);
}
.promo__title {
    font-size: 100px;
    font-weight: 500;
    position: relative;
    display: inline;
    padding: 0 0 10px 0;
    color: #514240;
}
.promo__title::after {
    content: '';
    
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    height: 1px;
    background:#807460 ;
    opacity: 0;
    -webkit-transition: height 0.3s, opacity 0.3s, -webkit-transform 0.3s;
    -moz-transition: height 0.3s, opacity 0.3s, -moz-transform 0.3s;
    transition: height 0.3s, opacity 0.3s, transform 0.3s;
    -webkit-transform: translateY(-10px);
    -moz-transform: translateY(-10px);
    transform: translateY(-10px);
}
.promo__title:hover::after {
    opacity: 1;
    height: 3px;
    -webkit-transform: translateY(0px);
    -moz-transform: translateY(0px);
    transform: translateY(0px);
}
.promo__subtitle {
    font-size: 25px;
    font-weight: 400;
}

.color__change {
    position: absolute;
    bottom: 0;
    width: 100%;
}

.main {
    
}
.title-wrapper {
    display: flex;
    align-items: center;
    border-top: solid 1px #CB997E;
    padding: 4vw 0;
}
.title-wrapper--gallery {
    flex-direction: column;
    align-items: center;
}

.main__title {
    flex-grow: 1;
    font-size: 50px;
    color: #8D7262;
    font-weight: 500;
    /* width: 60.97222vw; */
    margin: 0;
}
.title-center {
    text-align: center;
    width: 100%;
}
.title-wrapper--gallery .main__title {
    width: 100%;
    text-align: center;
    font-size: 50px;
    margin-bottom: 0;
}
.main__subtitle {
    color: #543c10;
    font-size: 2.5vw;
    font-weight: 600;
    /* margin: 0 0 15px 0; */
}
.page-descr {
    margin-left: auto;
    width: 40.06944vw;
    max-width: 100%;
}
.main__p {
    color: #8e6e35;
    font-weight: 500;
    font-size: 16px;
    max-width: 50vw;
    line-height: 1.7;
    letter-spacing: .02em;
    text-align: right;
}
.title-wrapper--gallery .main__subtitle {
    text-align: center;
}
.main__title-info {
    flex-basis: 50%;
    color: #918679;
}
.main__title-info .react-parallax {
    overflow: unset!important;
}
p.main__title-text {
    margin: 0;
    color: #514240;
    font-weight: 500;
    font-size: 2vw;
    line-height: 1.7;
    letter-spacing: .02em;
}




@-webkit-keyframes toBook-wrapper {
    100% {
        top: -12px;
        right: -12px;
        bottom: -12px;
        left: -12px;
        opacity: 0;
    }
}
@keyframes toBook-wrapper {
    100% {
        top: -12px;
        right: -12px;
        bottom: -12px;
        left: -12px;
        opacity: 0;
    }
}





/* ABOUT*/
.about__main_link {
    margin: 70px 0;
}

.about__img::after {
    content: '';
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0, 0.4);
}
.about__title {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 10;
    margin: 0;
    color: #EDDCD2;
    font-size: 50px;
    font-weight: 400;

    display: flex;
    align-items: center;
    justify-content: center;
}
.about__title img {
    display: inline-block;
    width: 70%;
}
.about__subtitle {
    font-size: 6.77778vw;
    color: #ceb286;
    
    font-weight: 500;
    font-style: italic;
}

.about__info {
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 80vh;
}
.about__info--reverse {
    flex-direction: row-reverse;
}
.about__info--center {
    flex-direction: column;
    margin-top: 5vw;
}
.about__info--center .about__text {
    text-align: center;
}

.about__info--reverse .about__subtitle {
    text-align: right;
}
.about__text-wrapper {
    flex-basis: 50%;
}
.about__subtitle {
    flex-grow: 1;
}

.btn-fixed {
    display: inline;
    position: absolute;
    right: 5%;
    bottom: 5%;
}
@media screen and (max-width: 1200px) {
    .promo__title {
        font-size: 70px;
    }
    .main__title {
        font-size: 45px;
    }
}
@media screen and (max-width: 1000px) {
    .whatsapp-link__wrapper {
        width: 50px;
        height: 50px;
    }
    .promo {
        margin-top: -83px;
        background-image: url(../../Assets/design/promo-bg-mobile.jpg);
        filter: brightness(1);
    }

    .promo__text {
        /* display: none; */
        width: 60%;
        text-align: center;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
    }
    .promo__text--mobile {
        display: block;

    }
    .feedback-wrapper {
        display: flex;
    }
    .booking-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}
@media screen and (max-width: 700px) {
    .promo__title {
        font-size: 50px;
    }
    h2.promo__subtitle {
        font-size: 20px;
    }
    p.main__title-text {
        font-size: 16px;
    }
}
@media screen and (max-width: 650px) {
    .title-wrapper--specialists {
        flex-direction: column;
        align-items: flex-start;
        text-align: center;
    }
    .title-wrapper--specialists .main__title {
        font-size: 40px;
        width: 100%;
    }
    .booking {
        font-size: 28px;
        padding: 10px;
    }
    span.booking-wrapper__tel {
        font-size: 20px;
    }
    span.booking-wrapper__tel::before {
        transform: translateY(20%);
    }
}

@media screen and (max-width: 600px) {
    .feedback-wrapper {
        width: 75vw;
        height: 75vw;
    }
}
@media screen and (max-width: 460px) {
    /* .title-wrapper {
        flex-direction: column;
    }
    .main__title-info {
        display: flex;
        flex-direction: column;
        align-items: center;
    } */
    /* .promo__text {
        width: 90%;
    }
    .promo__title {
        font-size: 50px;
    }
    .promo__subtitle {
        font-size: 20px;
    } */
    .promo__text {
        width: 70%;
    }
    p.main__title-text {
        font-size: 14px;
    }
    .main__p {
        font-size: 14px;
    }
    .main__title {
        font-size: 35px;
    }
    
    .main__subtitle {
        font-size: 16px;
    }
    .main__title-info {
        margin-top: 30px;
    }
    .main__title-info--services {
        margin: 0;
    }
    h2.about__subtitle {
        font-size: 36px;
    }
    
}
@media screen and (max-width: 400px) {
    .main__p {
        font-size: 11px;
    }
    .booking {
        font-size: 24px;
    }
}
@media screen and (max-width: 350px){
    .promo__title {
        font-size: 40px;
    }
}
