.servicePage-promo {
    margin-top: 10vw;
}

.servicePage-promo__content {
    display: flex;
    align-items: center;
    justify-content: center;
}
.servicePage-promo__content .react-parallax {
    overflow: unset!important;
}
.servicePage-promo__img {
    overflow: hidden;
    min-width: 49.30556vw;
    width: 49.30556vw;
    /* height: 65.97222vw; */
    height: 50vw;
}
.servicePage-promo__img img {
    /* display: block; */
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.servicePage-promo__descr {
    margin-left: -6.94444vw;
    padding: 5.41667vw 5vw 6.11111vw;
    z-index: 1;
    background: #C9BAAD;
    color: white;
}
.servicePage-promo__descr-min {
    display: none;
}
.servicePage-promo__title {
    font-size: 60px;
    font-weight: 200;
    font-style: italic;
}
.servicePage-promo__text {
    line-height: 30px;
    font-weight: 300;
    font-size: 24px;
}
.about-service__title-wrapper {
    margin: 100px 0;
    height: 70vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.about-service__title {
    text-align: center;
    font-size: 50px;
    color: #8A8EA0;
    font-weight: 500;
    padding: 100px;
}
.about-service__subtitle {
    font-size: 50px;
    font-weight: 300;
    color: #50403F;
}
p.about-service__descr {
    font-size: 16px;
    color: #514240;
    font-weight: 500;
    line-height: 1.7;
    letter-spacing: .02em;
}
.about-service__img div {
    font-style: italic;
    color: #ceb286;
    font-size: 20px;
}
.about-service {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10vw;
}
.about-service__img {
    overflow: hidden;
    /* min-width: 40%; */
    flex-basis: 40%;
}
.about-service__img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: block;
}
.about-service__text {
    flex-basis: 50%;
}


/*SERVICE BOOKING */

.book-service {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100vh;
}
.book-service__img {
    width: 50%;
    overflow: hidden;
}
.book-service__img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: block;
}
.service-form {
    background: #ceb286;
    width: 50%;
}
.service-form__in {
    display: flex;
    flex-direction: column;
}
.service-form__row {
    display: flex;
    justify-content: space-between;
}




@media screen and (max-width: 800px) {
    .servicePage-promo__title {
        font-size: 40px;
        margin: 0;
    }
    .servicePage-promo__text {
        font-size: 18px;
        line-height: 20px;
    }
    h1.about-service__title {
        padding: 10vw;
        font-size: 40px;
    }
    .about-service__title-wrapper {
        margin: 20vw 0 0;
    }
    p.about-service__descr {
        font-size: 14px;
    }
}
@media screen and (max-width: 650px) {
    .servicePage-promo__content {
        flex-direction: column;
        align-items: center;
    }
    .servicePage-promo__descr {
        margin-left: 0;
        text-align: center;
        display: none;
    }

    .servicePage-promo__descr-min {
        display: block!important;
        transform: translateY(-80px);
    }
    .about-service__title-wrapper {
        margin-top: 0;
    }
    
    .servicePage-promo__img {
        min-width: 70vw;
    }
}


@media screen and (max-width: 450px) {
    .servicePage-promo__content {
        height: 90vh;
    }
    .servicePage-promo__img {
        height: auto;
    }
    .about-service__img {
        display: none;
    }
    .about-service__text {
        flex-basis: auto;
    }
    .about-service__title-wrapper {
        margin: 0 0 0;
    }
}