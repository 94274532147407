.backdrop {
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: 20;
    background: rgba(0,0,0,0.5);
}
.pop-up {
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background: #EFEAE5;
    width: 70vw;
    z-index: 30;
}
.pop-up__close {
    position: absolute;
    top: 2vw;
    right: 2vw;
    cursor: pointer;
    z-index: 1000;
    width: 30px;
    height: 30px;
    border: none;
    outline: none;
    background: url(../../../src/Assets/design/close.png) center no-repeat;
    background-size: cover;
    transition-duration: 0.4s;
}
.pop-up__close:hover {
    transform: rotate(90deg)
}
.pop-up__container {
    display: flex;
    justify-content: space-between;
}
.booking__img {
    flex-basis: 50%;
    /* overflow: hidden; */
    background: linear-gradient( rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.5) ), url(https://images.unsplash.com/photo-1604241602470-fe8f3b2974f9?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=634&q=80);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    position: relative;
}
h1.pop-up__title {
    position: absolute;
    bottom: 0;
    left: 9%;
    letter-spacing: .1rem;
    width: 50%;
    color: white;
    font-size: 3vw;
    font-weight: 100;
    text-align: left;
}
.pop-up__content {
    padding: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-basis: 50%;
}
h2.pop-up__subtitle {
    font-size: 3vw;
    font-weight: 500;
    color: #8A8EA0;
    margin-top: 0;
}
.pop-up__form-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.pop-up__input {
    color: #918679;
    line-height: 45px;
    margin: 0 0 1em;
    text-transform: uppercase;
    border: 0.5px solid #C9BAAD;
    overflow: hidden;
    position: relative;
}
.pop-up__form-row .pop-up__input {
    flex-basis: 49%;
}
.pop-up__input-no_effect {
    overflow: unset;
}
.pop-up__input-no_effect::after {
    display: none;
}
.react-datepicker-wrapper {
    width: 100%;
}
.react-datepicker-popper {
    z-index: 999!important;
}
.pop-up__input::after {
    background: #ceb286;
    content: "";
    height: 155px;
    left: -75px;
    opacity: 0.2;
    position: absolute;
    top: -50px;
    transform: rotate(35deg);
    transition: all 2s cubic-bezier(0.19, 1, 0.22, 1);
    width: 50px;
    /* z-index: -10; */
}
.pop-up__input input {
    z-index: 20;
    line-height: 3;
    background: transparent;
    outline: none;
    border: none;
    padding: 0 1vw;
    font-size: 14px;
    color: #514240;
    width: 100%;
}
.pop-up__input input::placeholder {
    font-size: 14px;
    color: #9b8e83;
    line-height: 3;
}
.pop-up__input:hover {
    text-decoration: none;
}
.pop-up__input:hover::after {
    left: 120%;
    transition: all 2s cubic-bezier(0.19, 1, 0.22, 1);
}




.pop-up__btn-wrapper {
    text-align: center;
}

.pop-up__btn {
    transition: all 0.3s;
    position: relative;
    padding: 15px 20px;
    /* background: transparent; */
    background: #C9BAAD;
    cursor: pointer;

    border: none;
    outline: none;
    color: black;
}
.pop-up__btn span {
    transition: all 0.3s;
    color: #543c10;

    font-weight: 600;
    letter-spacing: 1px;
}
.pop-up__btn::before {
    content: '';
    position: absolute;
    bottom: -1.5px;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    opacity: 0;
    transition: all 0.3s;
    border-top-width: 1.5px;
    border-bottom-width: 1.5px;
    border-top-style: solid;
    border-bottom-style: solid;
    border-top-color: #918679;
    border-bottom-color: #918679;
    transform: scale(0.1, 1);
}

.pop-up__btn:hover span {
    letter-spacing: 2px;
    color: #543c10 ;
}
.pop-up__btn:hover::before {
    opacity: 1;
    transform: scale(1, 1);
}
.pop-up__btn::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    transition: all 0.3s;
    background-color: rgba(255, 255, 255, 0.5);
}
.pop-up__btn:hover::after {
    opacity: 0;
    transform: scale(0.1, 1);
}


.confirm__message {
    font-size: 24px;
    padding: 30px;

}


@media screen and (max-width: 950px) {
    .pop-up__form-row {
        flex-direction: column;
        align-items: stretch;
    }

}

@media screen and (max-width: 800px) {
    .pop-up__input {
        line-height: 25px;
    }
    .pop-up__btn {
        padding: 10px 20px;
    }

    h2.pop-up__subtitle {
    font-size: 24px;
}
}
@media screen and (max-width: 700px) {
    .pop-up__content {
        flex-basis: 70%;
        padding: 1.5rem;
    }
    .pop-up__btn span {

    }
}
@media screen and (max-width: 550px) {
    .pop-up__container {
        flex-direction: column;
    }
    .booking__img {
        display: none;
    }
    button.pop-up__close {
        width: 20px;
        height: 20px;
    }
    h2.pop-up__subtitle {
        font-size: 24px;
    }
    
}
@media screen and (max-width: 400px) {
    h2.pop-up__subtitle {
        font-size: 20px;
    }
    button.pop-up__close {
        width: 10px;
        height: 10px;
    }
}
