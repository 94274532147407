* {
  box-sizing: border-box;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /*background-image: url(Assets/design/background-main.png);*/
  background-size: cover;
  background-color: #EFEAE5;
  background-position: center;
  background-repeat: no-repeat;
  font-family: "Jost", "Forum", "Montserrat", sans-serif;

}
input {
  font: unset;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
a {
  text-decoration: none;
  color: inherit;
}

h1 {
  font-family: "Forum";
  letter-spacing: 3px;
}

ul {
  margin-top: 0;
}