@import url('https://fonts.googleapis.com/css?family=Nunito:400,700');

.card-service__title {
    color: white;
    font-size: 24px;
    line-height: 24px;
    font-weight: 700;
    margin-bottom: 4px;
}

.card-service__small {
    font-size: 14px;
    transition-duration: 0.5s;
    font-weight: 400;
    line-height: 20px;
    color: white;
}
.card-service__info {
    /* display: none; */
    opacity: 0;
    transition-duration: 0.5s;
}
.card-service__price {
    color: white;
    font-size: 35px;

}
.card-service__go-corner {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    z-index: 3;
    width: 52px;
    height: 52px;
    overflow: hidden;
    top: 0;
    right: 0;
    background-color: #C9BAAD;
    border-radius: 0 4px 0 52px;
}
.go-arrow {
    position: absolute;
    left: 20px;
    top: 10px;
    background-image: url(../../Assets/design/price-tags.png);
    background-size: cover;
    background-repeat: no-repeat;
    width: 25px;
    height: 25px;
}
.swiper-pagination-custom--cards {
    display: block;
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
}






.card-service__container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    padding-bottom: 70px;
    position: relative;
}