.map__wrapper {
    margin-top: 10vh;
}
.contacts-promo {
    /* background: url(./content/sign.jpg); */
    background-repeat: no-repeat;
    background-size: cover;
    height: 90vh;
    position: relative;
    overflow: hidden;
}
.contacts-promo__img--unset {
    overflow: unset!important;
}
.contacts-promo__title {
    font-size: 7.77778vw;
    /* color: #ceb286; */
    color: black;
    font-weight: 500;
    font-style: italic;
    width: 30vw;
    position: absolute;
    right: 15%;
    z-index: 2;
    margin: 0;
    bottom: 25%;
}
.contacts-promo__img {
    position: absolute;
    overflow: hidden;
    
}
.contacts-promo__img--square{
    width: 20vw;
    height: 20vw;
    left: 27%;
    z-index: 1;
    top: 30%;
}
.contacts-promo__img--rectangle {
    width: 25vw;
    left: 50%;
    transform: translateX(-50%);
    /* height: 50vw; */
    z-index: 0;
    top: 20%;
}
.contacts-promo__img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: block;
    
}
/* .contacts-promo::after {
    content: '';
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;
    top: 0;
    background: rgba(0, 0, 0, 0.5);
} */

@media screen and (max-width: 1000px) {
    
    .contacts-promo__img--rectangle {
        height: 100%;
        width: 40%;
    }
    .contacts-promo__img--square {
        width: 30%;
        height: 30vw;
        top: 40%;
        left: 15%;
    }
}
@media screen and (max-width: 750px) {
    .contacts-promo__img--square {
        width: 40%;
        left: 10%;
        height: 40%;
    }
}
@media screen and (max-width: 600px) {
    .contacts-promo__img--rectangle {
        width: 50%;
    }
    .contacts-promo__img--square {
        left: 10%;
    }
    .contacts-promo__title {
        top: 50%;
        left: 50%;
        bottom: unset;
        background: #fff;
        padding: 20px;
        transform: translate(-50%, -50%);
        width: auto;
    }
}
@media screen and (max-width: 480px) {
    /* .contacts-promo {
        height: 70vh;
    } */
    .contacts-promo__img--rectangle {
        width: 70%;
    }
    .contacts-promo__img--square {
        left: 0;
    }
}

