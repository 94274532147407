.footer {
    margin: 60px 0;
}
.footer__head {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-grow: 2;
}
.footer__head-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.line {
    flex-basis: 25%;
    height: 1px;
    background: #ceb286;
    flex-grow: 1;
}
.footer__content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 50px;
}
.footer__col {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 30%;
    color: #918679;
}
.footer__link {
    color: #918679;
    margin-bottom: 10px;
    font-size: 18px;
    text-align: center;
    position: relative;
}
.footer__link::after {
    content: '';

    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    height: 0.5px;
    background: #807460;
    content: '';
    opacity: 0;
    -webkit-transition: height 0.3s, opacity 0.3s, -webkit-transform 0.3s;
    -moz-transition: height 0.3s, opacity 0.3s, -moz-transform 0.3s;
    transition: height 0.3s, opacity 0.3s, transform 0.3s;
    -webkit-transform: translateY(-10px);
    -moz-transform: translateY(-10px);
    transform: translateY(-10px);
}
.footer__link:hover::after {
    opacity: 1;
    height: 1px;
    opacity: 1;
    -webkit-transform: translateY(0px);
    -moz-transform: translateY(0px);
    transform: translateY(0px);
}
.footer__title {
    color: #514240;
    font-weight: 400;
    margin: 10px 0;
    font-size: 40px;
    text-align: center;
}
.media {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    position: relative;
}
.media__link {
    display: inline-block;
    width: 30px;
    height: 30px;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
}
.media__link--whatsapp {
    background-image: url(../../Assets/design/whatsapp.png);
}
.media__link--insta {
    background-image: url(../../Assets/design/instagram.png);
}
.media__link--tg {
    background-image: url(../../Assets/design/telegram.png);
}
/* .media::before {
    content: '';
    display: block;
    position: absolute;
    background: #ceb286;
    width: 1px;
    height: 50px;
    left: 50%;
    top: 100px;
} */


.footer__form {
    text-align: center;
    margin-top: 100px;
    position: relative;
}
.footer__form::before {
    content: '';
    position: absolute;
    display: inline-block;
    height: 70px;
    width: 1px;
    top: -50%;
    left: 50%;
    background: #ceb286;

}


.footer__form-in {

    display: flex;
    flex-direction: column;
    align-items: center;
}
.footer__subtitle {
    font-weight: 400;
    color: #918679;
}
input {
    border: none;
    outline: none;
    background: transparent;
}



.footer__input {
    line-height: 3;
}
.footer__input::placeholder {
    text-align: center;
    height: 100%;
}





.footer__input-wrapper {
    color: #918679;
    cursor: pointer;
    line-height: 45px;
    margin: 0 0 1em;
    width: 20vw;
    position: relative;
    text-decoration: none;
    text-transform: uppercase;
    border: 1px solid #918679;
    overflow: hidden;
    position: relative;
}

.footer__input-wrapper:hover {
    text-decoration: none;
}

.footer__input-wrapper input {
    z-index: 20;
}
.footer__input-wrapper:after {
    background: #C9BAAD;
    content: "";
    height: 155px;
    left: -75px;
    opacity: 0.2;
    position: absolute;
    top: -50px;
    transform: rotate(35deg);
    transition: all 2s cubic-bezier(0.19, 1, 0.22, 1);
    width: 50px;
    z-index: -10;
}
.footer__input-wrapper:hover:after {
    left: 120%;
    transition: all 2s cubic-bezier(0.19, 1, 0.22, 1);
}
/* .footer__btn {
    background: #ceb286;
    border: none;
    outline: none;
    color: white;
    padding: 15px 30px;
} */


.footer__textarea-wrapper {
    height: 8vw;
}
.footer__textarea {
    line-height: 1;
    background: transparent;
    border: none;
    outline: none;
    padding: 1vw;
    width: 100%;
    height: 100%;
    font-size: 1.3vw;
    color: #807460;
}
.footer__textarea-wrapper::after {
    background: #ceb286;
    content: "";
    height: 400px;
    left: -185px;
    opacity: 0.2;
    position: absolute;
    top: -100px;
    transform: rotate(35deg);
    transition: all 1.5s cubic-bezier(0.19, 1, 0.22, 1);
    width: 100px;
    z-index: -10;

}
.footer__textarea-wrapper:hover:after {
    left: 120%;
    transition: all 1.5s cubic-bezier(0.19, 1, 0.22, 1);
}

.footer__btn {
    color: #FFF;
    transition: all 0.3s;
    position: relative;
    padding: 15px 20px;
    background: #C9BAAD;
    border: none;
    outline: none;
    color: black;
}
.footer__btn span {
    transition: all 0.3s;
    color: #543c10;
    font-size: 1.2vw;
    font-weight: 600;
    letter-spacing: 1px;
}
.footer__btn::before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    opacity: 0;
    transition: all 0.3s;
    border-top-width: 1px;
    border-bottom-width: 1px;
    border-top-style: solid;
    border-bottom-style: solid;
    border-top-color: #918679;
    border-bottom-color: #918679;
    transform: scale(0.1, 1);
}

.footer__btn:hover span {
    letter-spacing: 2px;
    color: #514240 ;
}
.footer__btn:hover::before {
    opacity: 1;
    transform: scale(1, 1);
}
.footer__btn::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    transition: all 0.3s;
    background-color: rgba(255, 255, 255, 0.5);
}
.footer__btn:hover::after {
    opacity: 0;
    transform: scale(0.1, 1);
}

.footer__bottom {

    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 50px;
    font-size: 13px;
}
.footer__bottom a {
    color: black;
}


@media screen and (max-width:850px) {
    .footer__input-wrapper {
        width: 30vw;
    }
}
@media screen and (max-width:700px) {
    a.footer__link {
        font-size: 14px;
    }
    .footer__content {
        margin-top: 1vw;
    }
    .media__link {
        width: 20px;
        height: 20px;
    }
    .footer__btn span {
        font-size: 12px;
    }
}
@media screen and (max-width:500px) {
    .footer__input-wrapper {
        width: 250px;
    }
}
@media screen and (max-width:450px) {
    a.footer__link {
        font-size: 11px;
    }
    .media__link {
        width: 15px;
        height: 15px;
    }
    .footer__bottom {
        flex-direction: column;
    }
    h1.footer__title {
        font-size: 30px;
    }
}
@media screen and (max-width: 400px) {
    .footer__p {
        text-align: center;
    }
}
