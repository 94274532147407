#burger {
    display: none;
}
#burgerBtn {
    position: absolute;
    left: 20px;
    top: 50%;
    transform: translateY(-50%);
}
@media screen and (max-width: 1000px) {
    #burger {
        display: block;
    }
    #burgerBtn {
        border-top: 2px solid #807460;
        height: 25px;
        width: 30px;
        box-sizing: border-box;
        position: absolute;
        z-index: 110;
        cursor: pointer;
        -webkit-transition: all 0.3s ease-in;
        -moz-transition: all 0.3s ease-in;
        -khtml-transition: all 0.3s ease-in;
        -o-transition: all 0.3s ease-in;
        transition: all 0.3s ease-in;
    }
    .coffee #burgerBtn {
        border-color: white;
    }


    #burgerBtn:before {
        content: "";
        display: block;
        position: absolute;
        height: 2px;
        width: 30px;
        left: 0;
        background: #807460;
        top: 10px;
        -webkit-transition: all 0.3s ease-in;
        -moz-transition: all 0.3s ease-in;
        -khtml-transition: all 0.3s ease-in;
        -o-transition: all 0.3s ease-in;
        transition: all 0.3s ease-in;
    }
    #burgerBtn:after {
        content: "";
        display: block;
        position: absolute;
        height: 2px;
        width: 30px;
        left: 0;
        background: #807460;
        bottom: 0;
        -webkit-transition: all 0.3s ease-in;
        -moz-transition: all 0.3s ease-in;
        -khtml-transition: all 0.3s ease-in;
        -o-transition: all 0.3s ease-in;
        transition: all 0.3s ease-in;
    }
    .coffee #burgerBtn:before, .coffee #burgerBtn:after {
        background-color: white;
    }

    #nav {
        opacity: 0;
        transition: opacity 0.3s ease;
        height: 100vh;
        background: #EFEAE5;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 100;
        list-style-type: none;
        overflow-y: scroll;
        padding: 40px 0;
        margin: 0;
        width: 0;
    }

    #nav li {
        padding: 25px 0;
        width: 0;
        margin: 30px 0;
        -webkit-transition: all 0.6s ease-in;
        -moz-transition: all 0.6s ease-in;
        -khtml-transition: all 0.6s ease-in;
        -o-transition: all 0.6s ease-in;
        transition: all 0.6s ease-in;
        text-align: right;
        font-size: 22px;
        font-weight: 500;
        color: #807460;


        position: relative;
    }

    #nav li:first-child {
        margin-top: 0!important;
    }

    #nav li::after {
        content: '';
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
        height: 1.5px;
        background: #807460;
        -webkit-transition: height 0.3s, opacity 0.3s, -webkit-transform 0.3s;
        -moz-transition: height 0.3s, opacity 0.3s, -moz-transform 0.3s;
        transition: height 0.3s, opacity 0.3s, transform 0.3s;
        -webkit-transform: translateY(-10px);
        -moz-transform: translateY(-10px);
        transform: translateY(-10px);

    }
    #nav .media__link  {
        width: 30px;
        height: 30px;
        margin-right: 20px;
    }
    #nav .media__link:last-child {
        margin-right: 0;
    }
    .navigation #nav {
        opacity: 1;
        width: 100%;
    }

    .navigation #nav li {
        /* width: 200px; */


        width: 70%;
        /* margin-left: 0; */

        text-align: center;
        margin: 30px 15%;
    }

    .navigation #burgerBtn {
        border-color: transparent;
    }

    .navigation #burgerBtn:before {
        -webkit-transform: rotate(45deg);
        -moz-transform: rotate(45deg);
        -khtml-transform: rotate(45deg);
        -o-transform: rotate(45deg);
        transform: rotate(45deg);
        width: 33px;
        left: -2px;
    }

    .navigation #burgerBtn:after {
        -webkit-transform: rotate(135deg);
        -moz-transform: rotate(135deg);
        -khtml-transform: rotate(135deg);
        -o-transform: rotate(135deg);
        transform: rotate(135deg);
        bottom: 11px;
        width: 33px;
        left: -2px;
    }
}