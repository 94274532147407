
.prices {
    background: #8D7262;
    padding:10vw 4vw;
}
h1.prices__title {
    color: #ecdabc;
    font-size: 4vw;
    font-weight: 400;
    text-align: center;
}
.prices__heading {
    font-size: 40px;
    margin: 0;
    position: relative;
    text-align: center;
}
.prices__heading__text {
    display: inline-block;
    padding: 15px 15%;
    background-color: #eddccd;
    transform: translateY(50%);
}
.prices__list {
    padding: 7rem;
    background: white;
}
.prices__row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 2rem
}
.prices__serviceName {
    max-width: 60%;
}
.prices__serviceName h4 {
    /* padding-bottom: 2rem; */
    font-size: 2vw;
    margin: 0;
    font-weight: 600;
}
.prices__serviceName p {
    color: #A5A58D;
    font-size: 1.2vw;
}
.prices__price--grid {
    display: flex;
    justify-content: flex-end;
}
.prices__price--grid div {
    margin-left: 1vw;
}
.prices__price {
    
}

.prices__price h4 {
    margin: 0;
    font-size: 1.5vw;
    font-weight: 600;
}
.prices__price p {
    margin: 0;
    font-size: 1.2vw;
}





.price-promo {
    height: 100vh;
    background-image: url(../../Assets/design/prices-parallax.jpg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
}
.price-promo::after {
    content: '';
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;
    top: 0;
    /* background: rgba(0, 0, 0, 0.5); */
    background: rgba(247, 239, 227, 0.3);
}


.price-promo__title {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 10;
    /* color: #EDDCD2; */
    color: black;
    font-size: 70px;
    font-weight: 400;
}
.white-string {
    position: absolute;
    bottom: -28px;
    background: white;
    z-index: 10;
    font-size: 2vw;
    padding: 1vw 5vw;
    font-weight: 300;
    font-style: italic;
    color: #8A8EA0;
    left: 50%;
    transform: translateX(-50%);
}




@media screen and (max-width: 1000px){
    h1.price-promo__title {
        font-size: 45px;
    }
    .prices {
        padding-top: 0;
    }
    .prices__list {
        padding: 3rem;
        padding-top: 4rem;
    }
    .prices__serviceName h4 {
        font-size: 20px;
    }
    .prices__serviceName p {
        font-size: 12px;
    }
    .prices__price h4 {
        font-size: 16px;
    }
    .prices__price p {
        font-size: 14px;
    }
    .white-string {
        font-size: 20px;
        text-align: center;
        line-height: 20px;
    }
}

@media screen and (max-width: 800px) {
    h1.price-promo__title {
        font-size: 45px;
    }
}
@media screen and (max-width: 500px){
    .prices__heading {
        font-size: 30px;
    }
    .prices {
        padding: 0 1vw 10vw 1vw;
    }
    .prices__list {
        padding: 2rem;
        padding-top: 4rem;
    }
    .prices__serviceName h4 {
        font-size: 16px;
    }
    .prices__serviceName p {
        font-size: 12px;
    }
    .prices__price h4 {
        font-size: 12px;
    }
    .prices__price p {
        font-size: 11px;
    }
}

@media screen and (max-width: 450px) {
    h1.price-promo__title {
        width: 100%;
        text-align: center;
        font-size: 35px;
    }
}
@media screen and (max-width: 390px) {
    .prices__list {
        padding: 1rem;
        padding-top: 3rem;
    }
}