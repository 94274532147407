.not-found__container {
    width: 100vw;
    height: 100vh;
}
.not-found__wrapper {
    position: absolute;
    left: 50%;
    text-align: center;
    top: 50%;
    padding: 50px;
    transform: translate(-50%, -50%);
}