.services-promo {
    height: 100vh;
    background-image: url(../../Assets/design/services-parallax.jpg);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    position: relative;
}
.services-promo::after {
    content: '';
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;
    top: 0;
    background: rgba(247, 239, 227, 0.3);
}
h1.services-promo__title {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 10;
    /* color: #EDDCD2; */
    /* color: #807460; */
    color: black;
    font-size: 70px;
    font-weight: 400;
}





.promo-marquee {
    margin: 4vw 0;
    font-size: 5vw;
    color: #8e6e35;
    font-style: italic;
    font-weight: 200;
}

.marquee {
    white-space: nowrap;
}

@media screen and (max-width: 800px) {
    h1.services-promo__title {
        font-size: 45px;
    }
}
@media screen and (max-width: 450px) {
    h1.services-promo__title {
        width: 100%;
        text-align: center;
        font-size: 35px;
    }
}
