.card-service__container .swiper-container {
    padding-bottom: 0;
}

.card-service {
    display: block;
    position: relative;
    flex-basis: 30%;  /* при переносе в реакт изменить*/
    /* background-color: #f2f8f9; */

    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    border-radius: 4px;
    padding: 32px 24px;
    text-decoration: none;
    z-index: 0;
    overflow: hidden;
    height: 120px;
    box-shadow: rgba(17, 12, 46, 0.15) 0 48px 100px 0;
    min-height: 180px;
}
.card-service::after {
    width: 100%;
    height: 100%;
    background: #000;
    position: absolute;
    opacity: 0.3;
    content: '';
    top: 0;
    transition-duration: 0.5s;
    left: 0;
    z-index: 1;
}
.card-service:before {
    content: "";
    position: absolute;
    z-index: -1;
    top: -16px;
    right: -16px;
    background: #C9BAAD;
    opacity: 0.7;
    height: 32px;
    width: 32px;
    border-radius: 32px;
    transform: scale(1);
    transform-origin: 50% 50%;
    transition: transform 0.25s ease-out;
}
.card-service__text {
    position: absolute;
    z-index: 2;
    bottom: 10%;
    transition-duration: 0.5s;
}
.card-service:hover:before {
    transform: scale(28);
}
.card-service:hover::after {
    background: transparent;
}
.card-service:hover .card-service__info {
    /* display: block; */
    opacity: 1;
}
.card-service:hover .card-service__small {

    transition: all 0.3s ease-out;
    color: rgba(255, 255, 255, 0.8);
}
.card-service:hover h3 {
    transition: all 0.3s ease-out;
    color: #fff;
}
.card-service:hover .card-service__go-corner {
    opacity: 0;
}

@media screen and (max-width: 1000px){
    .card-service__go-corner, .card-service__overlay, .card-service__info, .card-service::before{
        display: none;
    }
}