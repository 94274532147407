header {
    position: fixed;
    left: 0;
    width: 100%;
    top: 0;
    z-index: 100;
    transition-duration: 0.5s;
    padding: 20px 0;
}
.transparent {
    background-color: transparent;
}
.body {
    background-color: #EFEAE5;
}
.coffee {
    background-color: #8D7262;
}
.coffee .header__menu-link::after {
    background-color: #EDDCD2;
}
.coffee .header__menu-link {
    color: white;
}
.coffee .toBook{
    color: white;
    border-color: #EDDCD2;
}
.header {
    display: flex;
    justify-content: space-between;
    /* padding: 30px 0; */
    align-items: center;
    width: 90%;
    margin: 0 auto;
}
.header__menu-link {
    position: relative;
    display: inline-block;
    margin: 0 25px;
    outline: none;
    color: #514240;
    transition: color 0.5s ease;
    text-decoration: none;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-weight: 500;
    text-shadow: 0 0 1px rgb(255 255 255 / 30%);
    font-size: 18px;
}
.header__menu-link::after {
    content: '';

    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    height: 1px;
    background: #807460;
    opacity: 0;
    -webkit-transition: height 0.3s, opacity 0.3s, -webkit-transform 0.3s;
    -moz-transition: height 0.3s, opacity 0.3s, -moz-transform 0.3s;
    transition: height 0.3s, opacity 0.3s, transform 0.3s;
    -webkit-transform: translateY(-10px);
    -moz-transform: translateY(-10px);
    transform: translateY(-10px);
}
.header__menu-link:hover::after {
    opacity: 1;
    height: 3px;
    -webkit-transform: translateY(0px);
    -moz-transform: translateY(0px);
    transform: translateY(0px);
}



/* ONLINE APP*/
.toBook-wrapper {
    /* display: inline-block;
    vertical-align: middle;
    -webkit-transform: perspective(1px) translateZ(0);
    transform: perspective(1px) translateZ(0);
    box-shadow: 0 0 1px rgb(0 0 0 / 0%);
    position: relative;


    margin: .4em;
    padding: 1em;
    cursor: pointer;
    background: #F0EFEB;
    text-decoration: none;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;


    width: 100px;
    height: 100px;
    text-align: center;
    border-radius: 50%; */
    cursor: pointer;
    border-bottom: 1.5px solid #514240;
    border-top: 1.5px solid #514240;
    overflow: hidden;
    position: relative;
    margin: 0 0 10px;
}
.toBook {
    /* color: #bb9950;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 30%; */
    transition: color 1s ease;
    padding: 5px 0;
    border-bottom: 1.5px solid #514240;
    border-top: 1.5px solid #514240;
    cursor: pointer;
    z-index: 20;
    background: transparent;
    outline: none;
    font-size: 18px;

    color: #514240;
    font-weight: 500;
}

.toBook-wrapper:before {
    /* content: '';
    position: absolute;
    border: #F0EFEB solid 6px;
    border-radius: 50%;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    -webkit-animation-duration: 1s;
    animation-duration: 1s; */
    background: #C9BAAD;
    content: "";
    height: 155px;
    left: -75px;
    opacity: 0.2;
    position: absolute;
    top: -50px;
    transform: rotate(35deg);
    transition: all 2s cubic-bezier(0.19, 1, 0.22, 1);
    width: 50px;
    /* z-index: -10;*/
}
/* .toBook-wrapper:hover:before, .toBook-wrapper:focus:before, .toBook-wrapper:active:before {
    -webkit-animation-name: toBook-wrapper;
    animation-name: toBook-wrapper;
} */
.toBook-wrapper:hover {
    text-decoration: none;
}
.toBook-wrapper:hover::before {
    left: 120%;
    transition: all 2s cubic-bezier(0.19, 1, 0.22, 1);
}



header.header.header--light {
    width: 90%;
    /* margin: 0 auto; */
    z-index: 10;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
}
.header__menu--light a {
    color: #EDDCD2;
}



.logo {
    background: url(../../Assets/design/logo.png) center no-repeat;
    background-size: cover;
    width: 150px;
    height: 50px;
    transition-duration: 0.2s;
}
.coffee .logo {
    background-image: url(../../Assets/design/logo-light.png);
}

/* BARS*/

.bars {
    /* display: none; */
}




@media screen and (max-width: 750px) {
    .header__menu-link {
        font-size: 13px;
        margin: 10px 15px;
    }
}
@media screen and (max-width: 1000px) {
    .header__menu {
        display: none;
    }
    .header__menu-link {
        display: none;
    }
    .logo {
        width: 150px;
    }
    .toBook {
        display: none;
    }
}