.promo_slider {
    padding-bottom: 0;
}
.promos__title {
    font-size: 50px;
    color: #8D7262;
    font-weight: 400;
    text-align: center;
    margin-bottom: 0;
    margin-top: 3rem;
    line-height: 4rem;
}
.promotion-container {
    margin: 50px 0;
    /* border-top: 1px solid #CB997E; */
    padding-top: 30px;
}

.promotion {
    display: flex;
    align-items: center;
    justify-content: center;
}

.promotion__img {
    overflow: hidden;
    height: 100vh;
    transform: translateX(5%);
    /* flex-basis: 70%; */
    max-width: 50%;
}

.promotion__img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: block;
}

.promotion__text {

    /* height: 60vh; */
    /* background: rgba(255, 255, 255, 0.5); */
    background: hsla(0, 0%, 100%, .95);
    transform: translateX(-5%);
    box-shadow: 0.707px 0.707px 20px 0 rgb(89 89 89 / 10%);
    padding: 1rem 2rem;

}

.promotion__title h1 {
    color: #CB997E;
    font-size: 60px;
    font-weight: 400;
    font-style: italic;
    margin: 0;
}

.promotion__title h2 span {
    font-size: 50px;
    font-weight: 300;
    color: #dc7979;
}

.promotion__title h2 {
    color: #543c10;
    font-weight: 500;
}

.promotion__title .promotion-subtitle__marker {
    font-size: 24px;
}

.promotion__info {
    margin-top: 50px;
}

.promotion__info p {
    color: black;
    font-size: 18px;
    font-weight: 600;
}

.promotion__duration {
    color: #1d5d90;
}

.promotion__prices {
    display: flex;
    justify-content: space-around;
}

.price {
    font-weight: 300;
    font-size: 45px;
    font-style: italic;
    position: relative;
}

.price--initial {

}

.price--initial::after {
    content: "";
    position: absolute;
    display: block;
    width: 90%;
    height: 2px;
    background: black;
    top: 50%;
    transform: translateX(-10%);
}

.price--current {
    color: #dc7979;
}

.promotion__additional {
    font-size: 20px;
    text-align: right;
    font-weight: 200;
    font-style: italic;
}

.promotion__info i {
    color: #dc7979;
}


/*Swiper*/

.swiper-slide .promotion__img {
    opacity: 0;
    transform: translateX(-200%);
}

.swiper-slide .promotion__text {
    opacity: 0;
    transform: translateX(200%);
}

.swiper-slide .promotion__prices {
    opacity: 0;
}

.swiper-slide-active .promotion__img {
    opacity: 1;
    transform: translateX(10%);
    transition: opacity 2s, transform 1s;
}

.swiper-slide-active .promotion__text {
    opacity: 1;
    transform: translateX(-10%);
    transition: opacity 1s ease 1s, transform 1s;
    transition-delay: 0.3s;
}

.swiper-slide-active .promotion__prices {
    opacity: 1;
    transition: opacity 1s 1.5s;
}


@media screen and (max-width: 1100px) {
    .promotion__img {
        max-width: 60%;
    }

    .promotion__text {
        width: 60%;
    }

    .promotion__title h1 {
        font-size: 50px;
    }

    .promotion__title h2 {
        font-size: 18px;
    }
}

@media screen and (max-width: 1000px) {
    .promotion__img {
        margin-right: -25%;
        max-width: 70%;
    }
    .swiper-slide-active .promotion__img {
        transform: translateX(0);

    }

    .swiper-slide-active .promotion__text {
        transform: translateX(0);
    }
}

@media screen and (max-width: 770px) {
     .promotion__img {
        max-width: 85%;
        margin-right: -45%;
    }

    .promotion__title .promotion-subtitle__marker {
        font-size: 22px;
    }
}

@media screen and (max-width: 650px) {
    .promotion {
        flex-direction: column;
        align-items: center;
    }

   .promotion__img {
        max-width: 100%;
        height: auto;
        width: 100%;
        margin: 0;
    }

    .promotion__text {
        width: 100%;
    }

    .promotion__text {
        padding: 1rem 1.5rem;
    }

    .promotion__title h1 {
        font-size: 60px;
    }

    .promotion__title h2 {
        font-size: 24px;
    }

    .promotion__title .promotion-subtitle__marker {
        font-size: 28px;
    }

    .ptomotion__li {
        font-size: 20px;
    }
}

@media screen and (max-width: 500px) {
    .promotion__title h1 {
        font-size: 45px;
    }
    .promotion__title h2 {
        font-size: 18px;
    }
    .promotion__title h2 span {
        font-size: 30px;
        font-weight: bold;
    }
    .promotion__title .promotion-subtitle__marker {
        font-size: 22px;
    }
    .promotion__info p {
        font-size: 16px;
    }
}
@media screen and (max-width: 450px) {
    .promos__title {
        font-size: 40px;
        margin-top: 2rem;
        line-height: 3rem;
    }
}
@media screen and (max-width: 400px) {
    .promotion__title h1 {
        font-size: 35px;
        font-weight: bold;
    }
    .price {
        font-size: 30px;
        font-weight: bold;
    }
}